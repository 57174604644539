import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { Link } from 'react-router-dom';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Alert, Snackbar, AlertTitle } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { concatArrayToStringJoinBy, initValue, truncateWords } from '../../util/Index';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import { StatusAccompanimentModel } from '../../models/StatusAccompanimentModel'
import {
	getCompanies,
	downloadZipAllFilesByCompany,
	deleteEmpresaById,
	updateStatusEmpresa,
	updateEmpresaToActive,
	updateEmpresaToDesactive,
	getAccompaniment,
	deleteAcompaniment,
	putStatusAcompaniment,
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	Tooltip,
	TablePagination,
	Stack,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	FormControl,
	CircularProgress,
	Select,
	InputLabel
} from '@mui/material';

import DatePicker from "react-multi-date-picker";




interface StatusCompanyProps {
	id: number;
	status?: string;
	observaciones?: string;
}

const CompanyManagementPage = () => {
	const { isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const [selectedDates, setSelectedDates] = useState<any>([]);




	const colombia_es_lowercase: any = {
		name: "gregorian_es_lowercase",
		months: [
			["enero", "ene"],
			["febrero", "feb"],
			["marzo", "mar"],
			["abril", "abr"],
			["mayo", "may"],
			["junio", "jun"],
			["julio", "jul"],
			["agosto", "ago"],
			["septiembre", "sep"],
			["octubre", "oct"],
			["noviembre", "nov"],
			["diciembre", "dic"],
		],
		weekDays: [
			["sábado", "sáb"],
			["domingo", "dom"],
			["lunes", "lun"],
			["martes", "mar"],
			["miércoles", "mié"],
			["jueves", "jue"],
			["viernes", "vie"],
		],
		digits: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
		meridiems: [
			["AM", "am"],
			["PM", "pm"],
		],
	};


	const [data, setData]: any[] = useState([]);

	const [userData, setUserData]: any[] = useState([]);





	const [companyData, setCompanyData] = useState<StatusCompanyProps>({
		id: 0,
	});
	const [loading, setLoading] = useState(false);
	const [isSubmitting, setSubmitting] = useState(false);

	const [open, setOpen] = useState(false);
	const [idAcompaniment, setIdAcompaniment] = useState();
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	//Pagination
	const [total, setTotal] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: ''
	});
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	useEffect(() => {
		setFilter({ ...filter });
	}, []);

	UseDidMountEffect(() => {
		handleGetAccompaniment();
	}, [filter]);



	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { page: newPageNumber, limit: filter.limit, estado: filter.estado };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { page: 1, limit: parseInt(event.target.value, 10), estado: filter.estado }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};

	const handleClose = () => {
		setOpen(false);
	};

	// Get de los acompañamientos empresariales
	const handleGetAccompaniment = async () => {
		setLoading(true);
		const params ={
			pages: filter?.page,
			limit: filter?.limit,
			estado: filter?.estado,
			search: userData?.search,
			fecha_inicio: userData?.fecha_inicio,
			fecha_fin: userData?.fecha_fin,
		}
		try {
			const response = await getAccompaniment(params);
			if (response && response.data) {
				setTotal(response.data.total);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};



	const changeStatusEmpresa = async () => {
		try {
			setOpenAprobada(false);
			setOpenRechazada(false);
			setLoading(true);
			setSubmitting(true);
			const retorno = await updateStatusEmpresa(companyData);
			setAlertMsg(retorno.data?.message);
			setOpen(true);
			setAlert(true);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
			setTimeout(() => {
				setSubmitting(false);
			}, 2000);
		}
	};

	// Eliminar un acompañamiento 
	const handleDeleteAcompaniment = async (id: any) => {
		try {

			await deleteAcompaniment(id);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Acompañamiento eliminado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}

	}

	// acctualizar un acompañamiento
	const handlePutStatusAcompaniment = async (id: any, state: any) => {
		const close = { estado: "Cerrado", }
		try {
			await putStatusAcompaniment(id, close);
			setOpen(true);
			setAlert(true);
			handleCloseModal()
			setAlertMsg(`Acompañamiento cerrado con éxito!`);
			setFilter({ ...filter });
			setTimeout(() => {
				setOpen(false);
			}, 2000);
			setOpenModalCloseAcompaniment(false)
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
				setOpen(true);
				setAlert(false);
				setAlertMsg(error.response.data.error);
				setTimeout(() => {
					setOpen(false);
				}, 2000);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	}

	const MenuAction = (props: any) => {
		const [anchorEl, setAnchorEl] = useState(null);
		const handleClick = (e: any) => {
			setAnchorEl(e.currentTarget);
		};
		const handleClose = () => {
			setAnchorEl(null);
		};
		const { data } = props;



		return (
			<>
				<Button onClick={handleClick}>
					<i className="bi bi-three-dots" style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id="card-actions-menu"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{data.status === 'Cerrado' ? null : <MenuItem key="edit" onClick={handleClose}>
						<Link to={getPathEditarEmpresa(data.id)} style={{ width: '100%' }}>
							<BorderColorIcon />
							&nbsp; Editar
						</Link>
					</MenuItem>}

					<MenuItem key="delete" onClick={handleClose}>
						<button
							className={`${styleScss['button-link']} pointer`}
							onClick={() => handleOpenModal(data.id)}
						>
							<DeleteIcon />
							&nbsp; Eliminar
						</button>
					</MenuItem>
					{data.status === 'Cerrado' ? null : <MenuItem key="close" onClick={handleClose}>
						<button
							className={`${styleScss['button-link']} pointer`}
							onClick={() => handleOpenModalClose(data.id)}
						// onClick={() => handlePutStatusAcompaniment(data.id, 'Cerrado')}
						>
							<i className="bi bi-calendar2-x-fill" style={{ color: '#2266CC', marginLeft: 3, marginRight: 8 }}></i>
							&nbsp; Cerrar
						</button>
					</MenuItem>}



				</Menu>
			</>
		);
	};



	const [openModal, setOpenModal] = useState(false);
	const [openModalCloseAcompaniment, setOpenModalCloseAcompaniment] = useState(false);

	const handleOpenModal = (id: any) => {
		setIdAcompaniment(id);
		setOpenModal(true);
	};
	const handleOpenModalClose = (id: any) => {
		setIdAcompaniment(id);
		setOpenModalCloseAcompaniment(true);
	};

	const handleCloseModalAcompaniment = () => {
		setOpenModalCloseAcompaniment(false);
	};
	const handleCloseModal = () => {
		setOpenModal(false);
	};
	const [openAprobada, setOpenAprobada] = useState(false);
	const handleOpenAprobada = (id: any) => {
		setCompanyData({ id: id, status: 'Aprobada' });
		setOpenAprobada(true);
	};
	const handleCloseAprobada = () => {
		setOpenAprobada(false);
	};
	const [openRechazada, setOpenRechazada] = useState(false);
	const handleOpenRechazada = (id: any) => {
		setUserData({ ...userData, observaciones: '' });
		setCompanyData({ id: id, status: 'Rechazada' });
		setOpenRechazada(true);
	};
	const handleCloseRechazada = () => {
		setOpenRechazada(false);
		setUserData({ ...userData, observaciones: '' });
	};

	/**
	 * Valida si el rol del usuario en sesion es Administrador o Consejero
	 * @returns 
	 */
	const isAdminOrCounselor = () => {
		return isAdminRole() || isCounselorEmployerRole();
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */
	const getPathRegistroEmpresa = () => {
		if (isAdminRole()) {
			return '/admin/registro-de-empresa';
		} else if (isCounselorEmployerRole()) {
			return '/consejero/registro-acompanamiento-empresarial';
		} else {
			return 'auth/*';
		}
	}

	/**
	 * Retorna el path del usuario acorde al rol
	 * @returns 
	 */
	const getPathEditarEmpresa = (idAcompaniment: any) => {

		if (isCounselorEmployerRole()) {
			return `/consejero/registro-acompanamiento-empresarial/${idAcompaniment}`;
		} else {
			return 'auth/*';
		}
	}


	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		handleChangePage(0);
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, search: event.target.value });
	}


	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	const handleDateChange = (dates: any) => {
		setSelectedDates(dates);
		const searchDate = (startDate?: string, endDate?: string) => {
			setUserData({ ...userData, fecha_inicio: startDate, fecha_fin: endDate });
			setTimeout(() => {
				filtraTextoBuscar()
			}, 150);
		}
		if (dates?.length === 2) {
			const startDate = dates[0];
			const endDate = dates[1];
			searchDate(startDate.format("DD-MM-YYYY"), endDate.format("DD-MM-YYYY"))
		} else if (dates === null) {
			searchDate(undefined, undefined)
		}

	};

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15'
				style={{ backgroundColor: '#FFFF' }}
			>
				<LoadingBackdrop loading={isSubmitting} />
				<div className='row mb-4' style={{ alignItems: 'center' }}>
					<div className='col-sm-12'>
						<div className='input-group d-flex' style={{ width: '100%', alignItems: 'baseline' }}>
							{/* BackButton */}
							<div style={{ padding: '10px 0px 0px 15px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							{/* Filtro de estado */}
							<div className='col-lg-2 col-xs-12 col-sm-6 col-md-2' style={{ padding: '10px 1px 1px 15px' }}>
								<div className='input-group mb-3'>
									<FormControl sx={{ minWidth: 120 }} fullWidth size="small">
										<InputLabel id='estado_aprobacion'
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Estado</InputLabel>
										<Select
											labelId='estado_aprobacion'
											id='estado_aprobacion'
											label='Estado'
											value={initValue(filter.estado)}
											style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
											onChange={(e) => {
												setFilter({
													...filter,
													page: 1,
													estado: e.target.value
												});
												setPage(0);
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{StatusAccompanimentModel.map((item) => (
												<MenuItem key={item.code} value={item.value}>
													{item.value}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</div>
							</div>
							{/* Filtro de busqueda*/}
							<div className='col-lg-7 col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={'input-group mb-3'}>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Busca por NIT, Nombre de la empresa, Código vacante, Consejero Empleador'
										aria-label='Busca por NIT, Nombre de la empresa, Código vacante, Consejero Empleador'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchEmpresa'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							{/* Boton  para registrar un acompañamiento */}
							<div className='col-lg-2 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								{isAdminOrCounselor() && (
									<Link to={getPathRegistroEmpresa()}
										className='btn btn-primary btn-lg'
										role="button">Registrar Acompañamiento</Link>
								)}
							</div>
							{/* fitro de fechas */}
							<div className='col-lg-2 col-xs-12 col-sm-4 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<DatePicker
									value={selectedDates}
									range={true} locale={colombia_es_lowercase}
									title='rango de fechas' name='rangeDate'
									placeholder='Filtra por rango de fechas'
									dateSeparator=' - ' format='DD/MM/YY'
									onChange={handleDateChange}
									style={{ padding: 20, fontSize: 14, width: 225 }}
								/>
							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}

				<div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
					<Stack padding={2}>

						{/* <TableContainer component={Paper} className={`${styleScss['table-limit']}`} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}> */}
						<TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>

							<Table style={{ overflowY: 'visible' }}>

								<TableHead className={`${styleScss['th-custom']}`}>
									<TableRow>
										<TableCell style={{
											fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center',
											minWidth: '110px',

										}}>
											<h4>NIT</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', minWidth: '150px', }}>
											<h4>Nombre Empresa</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Código vacante</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', minWidth: '150px', }}>
											<h4>Nombre de la vacante</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Consejero
												empleador</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Tipo</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', minWidth: '120px', }}>
											<h4>Fecha</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Observaciones</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Vacantes registradas</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Estado</h4>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
											<h4>Acciones</h4>
										</TableCell>
									</TableRow>
								</TableHead>

								{!loading && <TableBody>
									{data.map((x: any, i: any) => (
										<TableRow key={i} id={i.toString()}>
											{!isEmployerRole() && (
												<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
													<Tooltip title={x.Empresa.numero_documento} placement='left' arrow>
														<p>
															{x.Empresa.numero_documento}
														</p>
													</Tooltip>
												</TableCell>
											)}
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{truncateWords(x.Empresa.razon_social.toLowerCase(), 35)}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x.EmpresaVaga.codigo_vacante}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x.EmpresaVaga.nombre_vacante}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x?.User?.UserComplemento?.primer_nome} {x?.User?.UserComplemento?.primer_apelido}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												{x.tipo == 'GESTION_ACTIVIDADES' ? 'Gestión actividades' : 'Otros servicios solicitados'}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>
												{x.fecha}
											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												<Tooltip title={x.observaciones} placement='left' arrow>
													<span>
														{truncateWords(x.observaciones, 60)}
													</span>
												</Tooltip>

											</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.Empresa.total_vacantes}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>{x.estado}</TableCell>
											<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
												<MenuAction data={{ id: x.id, active: x.active, status: x.estado }} />
											</TableCell>
										</TableRow>
									))}
								</TableBody>}

								{data.length === 0 && (
									<tfoot>
										<TableRow>
											<TableCell colSpan={8}>
												<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
													No se encontraron registros
												</p>
											</TableCell>
										</TableRow>
									</tfoot>
								)}

							</Table>
							<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
								<TablePagination
									rowsPerPageOptions={[10, 20, 50, 100]}
									component="div"
									style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
									count={total}
									rowsPerPage={rowsPerPage}
									page={page}
									onPageChange={(event, newPage) => handleChangePage(newPage)}
									onRowsPerPageChange={handleChangeRowsPerPage}
									labelRowsPerPage="Filas por página:"
									labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
									ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
										<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
											<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
												<FirstPageIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
												<ChevronLeftIcon />
											</IconButton>
											<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
												<ChevronRightIcon />
											</IconButton>
											<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
												<LastPageIcon />
											</IconButton>
										</div>
									)}
								/>
							</div>
						</TableContainer>
					</Stack>
				</div>
			</div>
			{/* modal de eliminar */}
			<div>
				<Dialog
					open={openModal}
					keepMounted
					onClose={handleCloseModal}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Eliminar acompañamiento empresarial'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							Esta acción eliminará permanentemente el acompañamiento.
						</DialogContentText>
						<br />
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea eliminar este acompañamiento?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModal}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handleDeleteAcompaniment(idAcompaniment)}
							autoFocus
							disabled={!loading ? false : true}
						>
							Eliminar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			
			{/* modal Cerrar */}
			<div>
				<Dialog
					open={openModalCloseAcompaniment}
					keepMounted
					onClose={handleCloseModalAcompaniment}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Confirme que desea cerrar este acompañamiento.'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea cerrar este acompañamiento?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalAcompaniment}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => handlePutStatusAcompaniment(idAcompaniment, 'cerrar')}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cerrar
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openAprobada}
					keepMounted
					onClose={handleCloseAprobada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>
						{'Confirmación de aprobación de empresa'}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea aprobar esta empresa?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseAprobada}
							disabled={isSubmitting}
							autoFocus
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							disabled={isSubmitting}
							autoFocus
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Aprobar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<div>
				<Dialog
					open={openRechazada}
					keepMounted
					onClose={handleCloseRechazada}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Rechazar la empresa'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea rechazar esta empresa?
						</DialogContentText>
						<DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
							Escriba las razones del rechazo a continuación
						</DialogContentText>
						<FormControl sx={{ minWidth: '100%' }}>
							<TextField
								fullWidth
								sx={{ width: 500, height: 250 }}
								rows={10}
								id='observaciones'
								name='observaciones'
								label='Observaciones'
								value={initValue(companyData['observaciones'])}
								onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
								size='medium'
								multiline
								inputProps={{ maxLength: 300 }}
								disabled={loading}
								helperText={`${companyData['observaciones'] === undefined ? '0' : companyData['observaciones']?.length}/${1200}`}
							/>
						</FormControl>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseRechazada}
							autoFocus
							disabled={isSubmitting}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							onClick={() => changeStatusEmpresa()}
							autoFocus
							disabled={companyData['observaciones'] === undefined || companyData['observaciones'] === '' || companyData['observaciones']?.trim() === ''}
						>
							<span style={{ display: 'flex', alignItems: 'center' }}>
								{isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
								{'Rechazar'}
							</span>
						</Button>
					</DialogActions>
				</Dialog>
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>
		</>
	);
};

const CompanyAccompanimentWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Acompañamiento empresarial</PageTitle>
			<CompanyManagementPage />
		</>
	);
};

export { CompanyAccompanimentWrapper };
