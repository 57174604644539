import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Link, useNavigate } from 'react-router-dom';
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers';
import { useAuth } from '../../../modules/auth';
import BackButton from '../../../components/BackButton/BackButton';
import { Alert, AlertTitle, Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Snackbar, TextField } from '@mui/material';
import Select from '@mui/material/Select';
import { DatePicker, esES, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TypeOfAccompanimentModel } from '../../../models/TypeOfAccompanimentModel';
import { useParams } from 'react-router-dom';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { getCompaniesCounselor, postCreateAccompaniment, getVacantes, getUsersConsejeroEmpresarial, getAccompanimentById, putStatusAcompaniment, updateAccompaniment, } from '../../../modules/apps/user-management/users-list/core/_requests';
import dayjs from 'dayjs';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage, FormikValues, FormikConfig, } from "formik";





const bussineFormInitial = {
	id_empresa: "",
	id_vacancy: "",
	id_consejero: "",
	compannyName: "",
	tipoAcompanamiento: "",
	tipo: "",
	codigo_vacante: '',
	vacancyName: '',
	consejeroEmpleador: '',
	fecha: null,
	observaciones: '',
	estado: "Gestion",
}



export const AccompanyingBusinessForm = () => {
	const navigate = useNavigate();

	const [initialState, setInitialState] = useState<typeof bussineFormInitial>(bussineFormInitial)

	const { id, nitEmpresa, idVacante } = useParams();



	const [openAlert, setOpenAlert] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');


	const [idEmpresa, setIdEmpresa] = useState('')
	const [companies, setCompanies] = useState([])
	const [listCounselorsEmpresa, setListCounselorsEmpresa] = useState<[]>([]);
	const [vacancies, setVacancies] = useState([{
		"id": '',
		"nombre_vacante": "",
		"codigo_vacante": "",
	},])

	// Dialog
	const [open, setOpen] = useState(false);
	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};
	// Dialog




	//Permite determinar si se carga el componente desde una sesion autenticada, ejemplo crear la empresa desde el perfil consejero
	const { auth } = useAuth();

	const handleShowSnackbar = (type: boolean, message: string) => {
		setAlert(type);
		setAlertMsg(message);
		setOpenAlert(true);
	};


	const handleCreateAccompaniment = async (data: any, resetForm: any) => {
		try {
			const response = await postCreateAccompaniment(data);
			if (response) {
				handleShowSnackbar(true, '¡Acompañamiento empresarial creado con éxito!')
				setTimeout(() => {
					setOpenAlert(false);
				}, 2000);
				resetForm();
			} else {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};

	const handleUpdateAccompaniment = async (data: any, id: any) => {
		try {
			const response = await updateAccompaniment(data, id);

			if (response) {
				handleShowSnackbar(true, '¡Acompañamiento empresarial actualizado con éxito!')
				setTimeout(() => {
					setOpenAlert(false);
				}, 2000);
				// resetForm();
			} else {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	};



	const handleGetAccompanimentById = async (id: any) => {
		try {
			const response = await getAccompanimentById(id);

			if (response) {
				handleShowSnackbar(true, '¡Información cargada con éxito!')
				setTimeout(() => {
					setOpenAlert(false);
				}, 2000);
				const {
					id_empresa: empresaId, // Alias para id_empresa
					observaciones,
					tipo,
					fecha,
					estado,
					id_user: userId, // Alias para id_user
					id_vaga: vagaId, // Alias para id_vaga
				} = response.data;
				const {
					id: empresaUniqueId, // Alias para id de Empresa
					razon_social,
					numero_documento,
				} = response.data.Empresa;
				const {
					codigo_vacante,
					id_empresa: empresaVagaId, // Alias para id_empresa de EmpresaVaga
					nombre_vacante,
					id: empresaVagaUniqueId, // Alias para id de EmpresaVaga
				} = response.data.EmpresaVaga;
				const {
					id: userUniqueId, // Alias para id de User
					role,
				} = response.data.User;
				const {
					primer_nome,
					primer_apelido,
				} = response.data.User.UserComplemento;

				setInitialState((prevState) => ({
					id_empresa: empresaId,
					id_vacancy: empresaVagaUniqueId,
					id_consejero: userUniqueId,
					compannyName: razon_social,
					tipoAcompanamiento: tipo,
					tipo: tipo,
					codigo_vacante: codigo_vacante,
					vacancyName: nombre_vacante,
					consejeroEmpleador: `${primer_nome} ${primer_apelido}`,
					fecha: fecha,
					observaciones: observaciones,
					estado: estado,
				}));

				setIdEmpresa(empresaId)
			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	}



	const handlePutStatusAcompaniment = async (id: any) => {
		const close = { estado: "Cerrado", }
		try {
			const response = await putStatusAcompaniment(id, close);


			if (response) {
				// handleShowSnackbar(true, '¡Acompañamiento cerrado con éxito!')
				handleShowSnackbar(true, '¡Acompañamiento cerrado con éxito!')
				setTimeout(() => {
					setOpenAlert(false);
				}, 2000);
				handleClose()
			}
		} catch (error: any) {
			if (error.response) {
				handleShowSnackbar(false, 'Ocurrió un error al procesar la solicitud.')
				console.error(error.response.data.error);
			} else {
				console.error("Ocurrio un error procesando la solicitud.");
			}
		}
	}



	// Traer las compañias 
	const getListCompanies = async () => {
		try {
			const response = await getCompaniesCounselor();
			setCompanies(response.data);
		} catch (error: any) {
			if (error.response) {
				console.error('error', error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	};

	// traer lista de vacantes con el id de la empresa
	const getListVacancy = async (id_empresa: any) => {
		// if (idEmpresa !== '') {
		try {
			const res = await getVacantes(id_empresa);

			setVacancies(res.data)
		} catch (error) {
			console.error('Error al obtener las vacantes:', error);
		}
		// }
	}


	// traer lista de consejeros
	const fetchListsCounselorEmpresa = async () => {
		const res = await getUsersConsejeroEmpresarial();
		await setListCounselorsEmpresa(res.data.data);
	};


	useEffect(() => {
		getListCompanies()
		if (id !== undefined) {
			handleGetAccompanimentById(id)
		}


		fetchListsCounselorEmpresa()
	}, [])

	useEffect(() => {
		if (idEmpresa !== '') {
			getListVacancy(idEmpresa)
		}
	}, [idEmpresa])

	useEffect(() => {
		if (nitEmpresa !== undefined) {
			getListVacancy(nitEmpresa)
		}
	}, [])



	const validationSchema = Yup.object({
		id_empresa: nitEmpresa
			? Yup.string() 
			: Yup.string().required("Seleccionar una empresa es obligatorio"), 
		tipoAcompanamiento: Yup.string().required("Seleccionar un tipo de acompañamiento es obligatorio"),
		codigo_vacante: idVacante
			? Yup.string()
			: Yup.string().required("El código de la vacante es obligatorio"),
		consejeroEmpleador: Yup.string().required('El consejero empleador es obligatorio'),
		fecha: Yup.date().required('La fecha es obligatoria'),
		observaciones: Yup.string()
			.min(50, 'las observaciones deben tener 50 caracteres como mínimo.')
			.max(700, 'Las observaciones no pueden exceder los 700 caracteres.')
	});


	const hadleVancayFind = (codigo_vacante: any) => {
		const item = vacancies.find((option) => option.codigo_vacante === codigo_vacante)
		if (item) {
			return item
		}
		return null
	}

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ width: '100%', height: '60%', display: 'flex', flexDirection: 'Column', justifyContent: 'center', alignItems: 'center' }}>
				<>
					{/* Si no existe una sesion autenticada muestra la seccion de logos */}
					{!auth && (
						<>
							{/* begin DIV Logo GOV.CO */}
							< div className='headergov2 d-flex flex-column pt-1 h-40px w-100' style={{ background: '#3366C9' }}>
								{/* begin::Logo */}
								<Link to='/' className='mb-1'>
									<img alt='Logo' src={'/media/logos/logo.png'} />
								</Link>
								{/* end::Logo */}
							</div>
							{/* begin DIV Logo Icons */}
							<div className='headergov2 d-flex flex-column pt-1 h-50px w-100' style={{ background: 'white' }} >
								<div>
									{/* begin::Aside mobile toggle */}

									<div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu' >
										<div className='btn btn-icon btn-active-color-primary w-40px h-40px' id='kt_aside_toggle' >
											<KTSVG path='/media/icons/duotune/abstract/abs015.svg' className='svg-icon-1' />
										</div>
									</div>
									{/* end::Aside mobile toggle */}

									{/* begin::Mobile logo */}
									<div className='d-flex align-items-center flex-grow-1 flex-lg-grow-0'>
										<Link to='/dashboard' className='d-lg-none'>
											<img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-bogota.png')} className='h-30px' />
										</Link>
									</div>
									{/* end::Mobile logo */}
									<Link to='/'>
										<div className='logobogota d-flex justify-content-left align-items-center' style={{ paddingRight: '15px' }} >
											<img alt='Logo-bogota' src={toAbsoluteUrl('/media/logos/logo-bogota.png')} className='h-40px' />
										</div>
									</Link>
								</div>
							</div>
						</>
					)}
				</>
				<Box sx={{ width: !auth ? '90%' : '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#FFFF', borderRadius: '10px', m: 5, p: 5 }}>
					<Box sx={{ width: '95%' }}>
						<Box>
							<div className='row mb-4'>
								<div className='col-sm-12'>
									<div className='input-group'>
										<div
											className='col-sm-1'
											style={{
												display: 'contents',
												justifyContent: 'center',
												alignItems: 'center',
											}}
										>
											<BackButton />
										</div>
										<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
											<h2 style={{ display: 'flex', gap: '10px' }}>Creación del acompañamiento empresarial</h2>
											<span style={{}}>Le agradecemos que diligencie los siguientes campos:</span>
										</div>
									</div>
								</div>
							</div>

							<Formik
								enableReinitialize={true}
								initialValues={initialState}
								validationSchema={validationSchema}
								onSubmit={(values, { resetForm }) => {
									const data = {
										// "id_empresa": values?.id_empresa,
										"id_empresa": nitEmpresa || values?.id_empresa,
										"id_vaga": idVacante || values?.id_vacancy,
										"id_user": values?.id_consejero,
										"tipo": values?.tipoAcompanamiento,
										"fecha": values?.fecha,
										"observaciones": values?.observaciones,
										"estado": values?.estado
									}
									// console.log('values',values)

									if (id === undefined) {
										handleCreateAccompaniment(data, resetForm)
									} else { handleUpdateAccompaniment(data, id) }

								}}
							>
								{({ setFieldValue, setFieldTouched, values, errors, touched, handleBlur, handleChange }) => (
									<Form>
										<Grid container direction='row' width={'100%'} mt={5}>
											{
												nitEmpresa === undefined ?
													<>
														<Grid lg={2} xs={12} md={6} mb={5} pr={2} item={true}>
															<Autocomplete
																disablePortal
																id="nit-companny"
																options={companies}
																getOptionLabel={(option: any) => `${option.numero_documento}`}
																renderOption={(props, option) => (
																	<li {...props} key={option.id}>
																		{option.razon_social} ({option.numero_documento})
																	</li>
																)}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="NIT"
																		error={!!(touched.id_empresa && errors.id_empresa)} // Mostrar error solo si el campo es tocado y tiene error
																		helperText={touched.id_empresa && errors.id_empresa ? <>{errors.id_empresa}</> : ''} // Mostrar el mensaje de error solo si hay un error
																	/>
																)}
																onChange={(_, value) => {
																	setFieldValue("id_empresa", value?.id || "");
																	setFieldValue("compannyName", value?.razon_social || "");
																	setIdEmpresa(value?.id || '')
																}}
																onBlur={() => setFieldTouched("id_empresa", true)} // Marca el campo como tocado cuando el usuario lo abandona
																value={companies.find((company: any) => company.id === values?.id_empresa) || null} // Busca la empresa seleccionada
															/>

														</Grid>
														<Grid lg={3} xs={12} md={6} mb={5} pr={2} item>
															<TextField
																fullWidth
																id="nombre-empresa"
																name="nombre-empresa"
																label="Nombre de la empresa"
																size="medium"
																value={values?.compannyName} // Usa el valor gestionado por Formik
																InputProps={{
																	readOnly: true, // Campo solo lectura
																}}
																InputLabelProps={{
																	shrink: !!values?.compannyName, // Asegura que el label se mantenga arriba si hay valor
																}}
															/>
														</Grid>
													</>
													: null
											}

											<Grid lg={nitEmpresa !== undefined ? 3 : 2} xs={12} md={6} mb={5} pr={2} item>
												<FormControl fullWidth error={Boolean(errors.tipoAcompanamiento && touched.tipoAcompanamiento)}>
													<InputLabel id="demo-simple-select-label">Tipo de acompañamiento</InputLabel>
													<Select
														labelId="demo-simple-select-label"
														id="demo-simple-select"
														value={values?.tipoAcompanamiento}
														label="Tipo de acompañamiento"
														onChange={(e) => {
															const selectedItem = TypeOfAccompanimentModel.find(item => item.value === e.target.value);
															setFieldValue("tipoAcompanamiento", e.target.value);
															setFieldValue("tipo", selectedItem?.code || "");
														}}


														onBlur={() => setFieldTouched("tipoAcompanamiento", true)} // Marca como tocado
													>
														<MenuItem value="">Seleccione...</MenuItem>
														{TypeOfAccompanimentModel.map((item) => (
															<MenuItem key={item.code} value={item.code}>
																{item.value}
															</MenuItem>
														))}
													</Select>
													<ErrorMessage name="tipoAcompanamiento">
														{(msg) => <div style={{ color: "red", fontSize: "0.8rem" }}>{msg}</div>}
													</ErrorMessage>
												</FormControl>
											</Grid>

											{
												idVacante === undefined ?
													<>
														<Grid lg={2} xs={12} md={6} mb={5} pr={2} item={true}>
															<Autocomplete
																disablePortal
																id="codigo-vacante"
																options={vacancies}

																value={
																	values?.codigo_vacante
																		// ? vacancies.fin((option) => option.codigo_vacante === values?.codigo_vacante)
																		? hadleVancayFind(values.codigo_vacante)
																		: null
																}

																getOptionLabel={(option) => option?.codigo_vacante || ''}
																renderInput={(params) => (
																	<TextField
																		{...params}
																		label="Código de la vacante"
																		error={Boolean(errors.codigo_vacante && touched.codigo_vacante)}
																		helperText={touched.codigo_vacante && <>{errors.codigo_vacante}</>}
																	/>
																)}
																onChange={(_, value) => {
																	setFieldValue('id_vacancy', value ? value.id : '');
																	setFieldValue('codigo_vacante', value ? value.codigo_vacante : '');
																	setFieldValue('vacancyName', value?.nombre_vacante || '');
																}}
																onBlur={() => setFieldTouched('codigo_vacante', true)} // Marca el campo como tocado
															/>
														</Grid>
														<Grid lg={3} xs={12} md={6} mb={5} pr={2} item>
															<TextField
																fullWidth
																id="nombre-empresa"
																name="nombre-empresa"
																label="Nombre de la vacante"
																size="medium"
																value={values?.vacancyName} // Usa el valor gestionado por Formik
																InputProps={{
																	readOnly: true, // Campo solo lectura
																}}
																InputLabelProps={{
																	shrink: !!values?.vacancyName, // Asegura que el label se mantenga arriba si hay valor
																}}
															/>
														</Grid>
													</>
													: null
											}


											{nitEmpresa !== undefined ? <Grid lg={2}></Grid> : null}
											<Grid container direction={idVacante=== undefined ? "column": "row"} width={idVacante === undefined ? '30%' : '84%'} >

												<Grid lg={idVacante === undefined ? 2 : 5} xs={12} md={6} mb={5} pr={2} item>
													<Autocomplete
														disablePortal
														id="consejero-empleador"
														options={listCounselorsEmpresa}
														value={
															values?.id_consejero
																? listCounselorsEmpresa.find((option: any) => option.id === values?.id_consejero)
																: null
														} // Vincula el valor al estado de Formik
														getOptionLabel={(option: any) => `${option.UserComplemento.primer_nome} ${option.UserComplemento.primer_apelido}`}
														onChange={(e, value: any) => {
															setFieldValue('consejeroEmpleador', value ? value.id : '');
															setFieldValue('id_consejero', value ? value.id : '');
														}}
														renderInput={(params: any) => (
															<TextField
																{...params}
																label="Consejero empleador"
																variant="outlined"
																fullWidth
																error={touched.consejeroEmpleador && Boolean(errors.consejeroEmpleador)}
																helperText={touched.consejeroEmpleador && errors.consejeroEmpleador}
															/>
														)}
													/>
												</Grid>
												<Grid lg={idVacante === undefined ? 2 : 5} xs={12} md={3} mb={5} pr={2} item>
													<LocalizationProvider
														dateAdapter={AdapterDayjs}
														localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText}
														adapterLocale="es"
													>
														<FormControl sx={{ minWidth: '100%' }} size="medium">
															<DatePicker
																disablePast
																format="DD/MM/YYYY"
																slotProps={{
																	textField: {
																		required: true,
																		fullWidth: true,
																		id: 'fecha',
																		label: 'Fecha',
																		variant: 'outlined',
																		error: touched.fecha && Boolean(errors.fecha),
																		helperText: touched.fecha && <>{errors.fecha}</>,
																	},
																	actionBar: { actions: ['clear', 'cancel', 'today'] },
																}}
																// value={values?.fecha}
																value={dayjs(values.fecha, 'YYYY-MM-DD')}
																onChange={(date: any) => {
																	const formattedDate = date ? dayjs(date).toISOString() : null;
																	setFieldValue('fecha', formattedDate);
																}}
															/>
														</FormControl>
													</LocalizationProvider>
												</Grid>
											</Grid>
											<Grid width={'70%'} item={true} >
												<Grid xs={12} mb={5} pr={2} item>
													<TextField
														fullWidth
														label="Observaciones"
														placeholder="Observaciones"
														multiline
														minRows={5}
														maxRows={5}
														inputProps={{
															maxLength: 1000,
														}}
														InputProps={{
															style: {
																height: '145px',
															},
														}}
														name="observaciones"
														value={values?.observaciones}
														onChange={handleChange}
														onBlur={handleBlur}
														error={touched.observaciones && Boolean(errors.observaciones)}
														helperText={touched.observaciones && <>{errors.observaciones}</>}
													/>


												</Grid>
											</Grid>


										</Grid>
										<div className="d-flex justify-content-center" style={{ width: '100%', }}>
											<div className="row d-flex justify-content-around" style={{ width: "50%" }}>
												{id === undefined ? <></> :
													<Button
														onClick={handleClickOpen}
														variant='contained'
														sx={{ background: '#0A4396', width: 90 }}
													>
														Cerrar
													</Button>}

												<Button
													variant='contained'
													sx={{ background: '#0A4396', mr: 1, width: 90, height: 40 }}
													type="submit"
												>
													{id === undefined ? 'Guardar' : 'Actualizar'}
												</Button>
											</div>

											{/*  Dialog */}
											<Dialog
												open={open}
												onClose={handleClose}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
											>
												<DialogTitle id="alert-dialog-title">
													{"Confirme que desea cerrar este acompañamiento."}
												</DialogTitle>
												<DialogActions>
													<Button className='btn btn-danger'
														// onClick={handleClose}
														onClick={() => handlePutStatusAcompaniment(id)}
													>Confirmar el cierre</Button>

													<Button className='btn btn-primary' onClick={handleClose} autoFocus>
														Cancelar el cierre
													</Button>
												</DialogActions>
											</Dialog>

										</div>
									</Form>
								)}
							</Formik>
							<Grid container direction='row' width={'100%'} mt={5}>
							</Grid>

						</Box>
						<Snackbar
							anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
							open={openAlert}
							onClose={() => setOpenAlert(false)}
						>
							<Alert
								onClose={() => setOpenAlert(false)}
								variant={'filled'}
								severity={alert ? 'success' : 'error'}
								sx={{ whiteSpace: 'pre-line' }}
							>
								{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
								{alertMsg}
							</Alert>
						</Snackbar>
					</Box>
				</Box>
			</Box>
		</Box >
	);
};
