import BackButton from '../../../components/BackButton/BackButton';
import {
	Box,
	TextField,
	FormControl,
	InputLabel,
	Divider,
	MenuItem,
	Select,
	Button,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
	FormHelperText,
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { isValidFieldRequired, initValue, setValueOrDefault } from '../../../util/Index';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { esES } from '@mui/x-date-pickers/locales';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createInformacionAdicionalEmpresa, getUsersConsejeroEmpresarial } from '../../apps/user-management/users-list/core/_requests';
import { MouseEvent, useContext, useEffect, useMemo, useState } from 'react';
import { multiStepContext } from '../../../contexts/InformacionAdicionalEmpresaContext';
import { useNavigate, useParams } from 'react-router-dom';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../../components/LoadingSpinner/LoadingSpinner';
import useInfoAdicionalParams from '../../../hooks/parameters/company-aditional-info/useInfoAdicionalParams';
import { updateInformacionAdicionalEmpresa, getInformacionAdicionalEmpresa } from '../../apps/user-management/users-list/core/_requests';

export const InformacionAdicionalEmpresa = () => {
	const [listCounselorsEmpresa, setListCounselorsEmpresa] = useState<[]>([]);
	const [loading, setLoading] = useState(false);
	const { userData, setUserData } = useContext(multiStepContext);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [errorFechaAsignacionConsejero, setErrorFechaAsignacionConsejero] = useState(false);
	// id es el id de la empresa que se encuentra en los parametros del navegador
	const { id } = useParams();
	const { listFuentes, loadingParams } = useInfoAdicionalParams();
	const navigate = useNavigate();
	const [correo, setCorreo] = useState('');

	const handleClose = () => {
		setOpen(false);
	};

	class validationForm {
		fecha_asignacion_consejero: string = initValue(userData['fecha_asignacion_consejero']);
		fecha_registro_ade: string = initValue(userData['fecha_registro_ade']);
		consejero: string = initValue(userData['id_consejero']);
		fuente: string = initValue(userData['fuente']);
	}

	const formSchema = useMemo(
		() =>
			Yup.object().shape({
				fecha_asignacion_consejero: Yup.string().nullable().required('Campo requerido'),
				fecha_registro_ade: Yup.string().required('Campo requerido'),
				consejero: Yup.string().required('Campo requerido'),
				fuente: Yup.string().required('Campo requerido'),
			}),
		[]
	);

	const formik = useFormik({
		initialValues: new validationForm(),
		validationSchema: formSchema,
		initialTouched: {
			fecha_asignacion_consejero: false,
			fecha_registro_ade: false,
			consejero: false,
			fuente: false
		},
		enableReinitialize: true,
		validateOnMount: true,
		onSubmit: (values, { setStatus, setSubmitting }) => {
			try {
				setSubmitting(true);
				setStatus('');
			} catch (error) {
				console.error(error);
			}
		},
	});

	const getAllParameters = async () => {
		await fetchListsCounselorEmpresa();
	};

	useEffect(() => {
		getAllParameters();
		getInformacionAdicionalEmpresaAPI();
	}, []);

	/**
	 * Llamado al API para consulta de informacion
	 */
	const getInformacionAdicionalEmpresaAPI = async () => {
		if (id !== undefined && parseInt(id) > 0) {
			try {
				setLoading(true);
				const response = await getInformacionAdicionalEmpresa(parseInt(id));
				if (response?.data && Object.entries(response?.data).length == 0) {
					const fecha = new Date().toISOString().split('T')[0];
					setUserData({ ...userData, fecha_registro_ade: fecha, id_empresa: id });
				} else {
					setUserData(response.data);
				}
			} catch (error: any) {
				if (error.response) {

					console.error('error', error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			}
			finally {
				setLoading(false);
			}
		} else {
			const fecha = new Date().toISOString().split('T')[0];
			setUserData({ ...userData, fecha_registro_ade: fecha, id_empresa: id });
		}
	}

	useEffect(() => {
		const consejero = listCounselorsEmpresa.find(({ id }) => id === userData.id_consejero) || { email: '' };
		setCorreo(consejero.email);
	}, [listCounselorsEmpresa, userData]);

	const fetchListsCounselorEmpresa = async () => {
		const res = await getUsersConsejeroEmpresarial();
		setListCounselorsEmpresa(res.data.data);
	};

	const fieldsAreValid = async () => {
		let errors: { field: string; error: string }[] = [];
		if (!isValidFieldRequired(userData['id_consejero']))
			errors.push({ field: 'consejero', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['fecha_asignacion_consejero']))
			errors.push({ field: 'fecha_asignacion_consejero', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['fuente']))
			errors.push({ field: 'fuente', error: 'Campo requerido' });

		if (!isValidFieldRequired(userData['fecha_registro_ade']))
			errors.push({ field: 'fecha_registro_ade', error: 'Campo requerido' });

		if (errors.length > 0) {
			setAlert(false);
			setAlertMsg(
				`\nRellene los campos obligatorios\n ${errors.map((x) => {
					return `\n ${x.field.replaceAll('_', ' ').replace(/^\w/, (c) => c.toUpperCase())}: ${x.error
						}`;
				})}`
			);
			setOpen(true);
			setTimeout(() => {
				setOpen(false);
			}, 5000);
			return false;
		}
		if (Object.keys(formik.errors).length) {
			return false;
		}
		return true;
	};

	const handleSubmit = async (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		event.preventDefault();
		try {
			formik.handleSubmit();
			setRequestInProgress(true);
			const isValidToContinue = await fieldsAreValid();
			if (!isValidToContinue)
				throw new Error('Fields not valid');
			if (userData.id !== undefined && parseInt(userData.id) > 0) {
				await updateInformacionAdicionalEmpresa(userData);
				setAlert(true);
				setAlertMsg('¡Registro editado con éxito!');
			} else {
				await createInformacionAdicionalEmpresa(userData);
				setAlert(true);
				setAlertMsg('¡Registro creado con éxito!');
			}
			setOpen(true);
			setTimeout(() => {
				navigate(-1);
			}, 2000);
		} catch (error: any) {
			if (error.response) {
				setOpen(true);
				setAlert(false);
				setAlertMsg(`${error.response.data.error}`);
			}
			else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setTimeout(() => {
				setRequestInProgress(false);
			}, 2000);
		}

	};

	return (

		<Box sx={{ width: '100%', background: 'white', padding: 4, borderRadius: 2, marginTop: '40px' }}>

			<Box sx={{ width: '95%' }}>
				<Box>
					<div className='row mb-5'>
						<div className='col-sm-12'>
							<div className='input-group'>
								<div
									className='col-sm-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<BackButton />
								</div>
								<div className='col-sm-10' style={{ padding: '0px 0px 0px 20px' }}>
									<h2 style={{ display: 'flex', gap: '10px' }}>Información Adicional</h2>
									<span style={{}}>Consejero, diligencie los campos con información adicional de la empresa.</span>
								</div>
							</div>
						</div>
					</div>
				</Box>
			</Box>
			

			<Box mt={5}>
				<LoadingBackdrop loading={requestInProgress} />
				{loading ? (<LinearLoadingSpinner isLoading={true}/>) : (
					loadingParams ? <LinearLoadingSpinner isLoading={true} /> :
						<>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ m: 2, minWidth: '95%' }}
										size='medium'
									>
										<InputLabel required>Consejero asignado</InputLabel>
										<Select
											{...formik.getFieldProps('consejero')}
											error={formik.touched.consejero && Boolean(formik.errors.consejero)}
											id='consejero'
											name='consejero'
											label='Consejero asignado'
											value={initValue(userData['id_consejero'])}
											onChange={(e) => {
												const consejero = listCounselorsEmpresa.find(({ id }) => id === e.target.value) || { email: '' };
												setUserData({ ...userData, id_consejero: e.target.value, correo_consejero: consejero.email });
												formik.setFieldTouched('consejero', true);
												formik.setFieldValue("consejero", e.target.value)
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>

											{listCounselorsEmpresa.map(({ email, id, UserComplemento: { primer_nome, segundo_nome, primer_apelido, segundo_apelido } }) => (
												<MenuItem value={id} key={id}>
													{primer_nome} {segundo_nome} {primer_apelido} {segundo_apelido}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.consejero ? formik.errors.consejero : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ m: 2, minWidth: '95%' }}
										size='medium'
									>
										<TextField
											required
											fullWidth
											id='correo_consejero'
											name='correo_consejero'
											label='Correo electrónico del consejero'
											placeholder='Correo electrónico del consejero'
											value={correo}
											disabled={true}
											size='medium'
											inputProps={{ maxLength: 300 }}
										/>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
										<FormControl sx={{ m: 2, minWidth: '95%' }} size='medium' error={(formik.touched.fecha_asignacion_consejero && Boolean(formik.errors.fecha_asignacion_consejero))}>
											<DatePicker
												format='DD/MM/YYYY'
												value={dayjs(formik.values.fecha_asignacion_consejero, 'YYYY-MM-DD')}
												onChange={(e) => {
													if (e === null || !(e?.isValid())) {
														formik.setFieldValue("fecha_asignacion_consejero", null);
														setUserData({ ...userData, fecha_asignacion_consejero: null });
													} else {
														const formattedDate = e.format('YYYY-MM-DD');
														formik.setFieldValue("fecha_asignacion_consejero", formattedDate);
														setUserData({ ...userData, fecha_asignacion_consejero: formattedDate });
													}
													formik.setFieldTouched('fecha_asignacion_consejero', true)
												}}
												onOpen={() => formik.setFieldTouched('fecha_asignacion_consejero', true)}
												onClose={() => formik.setFieldTouched('fecha_asignacion_consejero', true)}
												onError={(error, value) => { formik.setFieldTouched('fecha_asignacion_consejero', true); }}
												slotProps={{
													textField: {
														onBlur: () => formik.setFieldTouched('fecha_asignacion_consejero', true),
														onClick: () => formik.setFieldTouched('fecha_asignacion_consejero', true),
														required: true,
														fullWidth: true,
														id: 'fecha_asignacion_consejero',
														name: 'fecha_asignacion_consejero',
														label: 'Fecha asignación del consejero',
														variant: 'outlined',
														error: (formik.touched.fecha_asignacion_consejero && Boolean(formik.errors.fecha_asignacion_consejero)),
														helperText: (formik.touched.fecha_asignacion_consejero && Boolean(formik.errors.fecha_asignacion_consejero)) ? 'Campo requerido' : ''
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
											/>
										</FormControl>
									</LocalizationProvider>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ m: 2, minWidth: '95%' }}
										size='medium'
									>
										<LocalizationProvider dateAdapter={AdapterDayjs} localeText={esES.components.MuiLocalizationProvider.defaultProps.localeText} adapterLocale="es">
											<DatePicker
												format='DD/MM/YYYY'
												defaultValue={dayjs(new Date())}
												slotProps={{
													textField: {
														required: true,
														fullWidth: true,
														id: 'fecha_registro_ade',
														label: 'Fecha de registro en la ADE (Automático)',
														variant: 'outlined'
													},
													actionBar: { actions: ["clear", "cancel", "today"] }
												}}
												disabled={true}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container direction='row' justifyContent='space-between' width={'100%'}>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}>
									<FormControl
										sx={{ m: 2, minWidth: '95%' }}
										size='medium'
									>
										<InputLabel required>Fuente</InputLabel>
										<Select
											{...formik.getFieldProps('fuente')}
											error={formik.touched.fuente && Boolean(formik.errors.fuente)}
											id="fuente"
											name='fuente'
											label='Fuente'
											value={initValue(userData['fuente'])}
											onChange={(e) => {
												setUserData({ ...userData, fuente: e.target.value });
												formik.setFieldTouched('fuente', true);
												formik.setFieldValue("fuente", e.target.value)
											}}
										>
											<MenuItem value=''>Seleccione...</MenuItem>
											{listFuentes.map(({ value, code, label }) => (
												<MenuItem
													key={value}
													value={code}
												>
													{label}
												</MenuItem>
											))}
										</Select>
										<FormHelperText sx={{ color: '#d32f2f' }}>
											{formik.touched.fuente ? formik.errors.fuente : ''}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid xs={12} md={6} mb={5} pr={2} item={true}></Grid>
							</Grid>
							<Divider sx={{ border: 1, borderColor: '#B0B0B0', mb: 1 }} />
							<Grid xs={12} md={6} mb={5} pr={2} item={true} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', pt: 2 }}>
								<Button
									variant='contained'
									sx={{ background: '#0A4396', width: 90, mr:1 }}
									onClick={(e) => {
										handleSubmit(e)
									}}
									disabled={formik.isSubmitting || !formik.isValid}
								>
									Enviar
								</Button>
							</Grid>		
							<Snackbar
								anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
								open={open}
								onClose={handleClose}
							>
								<Alert
									onClose={handleClose}
									variant={'filled'}
									severity={alert ? 'success' : 'error'}
									sx={{ whiteSpace: 'pre-line' }}
								>
									{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
									{alertMsg}
								</Alert>
							</Snackbar>
						</>
				)}

			</Box>

		</Box>

	);
};
