import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import {
	TableContainer,
	Table,
	TableHead,
	TableCell,
	TableRow,
	TableBody,
	TablePagination,
	IconButton,
	Paper,
	FormControl,
	InputLabel,
	Select,
	Snackbar,
	Alert,
	AlertTitle,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions
} from '@mui/material';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { useNavigate, Link, useParams } from 'react-router-dom';
import { getVagaPostulaCurriculoAll } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { initValue } from '../../util/Index'
import { listPostulacionModel } from '../../models/ListPostulacionModel'
import { updatePostulationStatus, submitCandidate, downloadPdfMasivo } from '../../modules/apps/user-management/users-list/core/_requests'
import VerifiedIcon from '@mui/icons-material/Verified';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import SendIcon from '@mui/icons-material/Send';
import React from 'react';

const EmployerRequestedCandidatoPostularPage = () => {
	
	const [selectedItems, setSelectedItems] = useState<any>([]);
	
	
	const { currentUser, auth, isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
	const { id_vacante } = useParams();
	const [data, setData]: any[] = useState([]);
	const [userData, setUserData]: any[] = useState([]);
	const navigate = useNavigate();
	const [total, setTotal] = useState<number>(0);
	const [totalPage, setTotalPage] = useState<number>(0);
	const [filter, setFilter] = useState<any>({
		page: 1,
		limit: 10,
		estado: '',
		autoPostulacion: null,
	});
	const [loading, setLoading] = useState(false);
	const id_users = currentUser?.user?.id;
	const id_empresa = currentUser?.user?.empresa_id;
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [requestInProgress, setRequestInProgress] = useState(false);
	const [open, setOpen] = useState(false);
	const [alert, setAlert] = useState(true);
	const [alertMsg, setAlertMsg] = useState('');


	console.log(selectedItems)
	const handleCheck = (id: any, isChecked: any) => {

		setSelectedItems((prev: any) =>
			isChecked
				? [...prev, id] // Agrega el ID al estado si está marcado
				: prev.filter((item: any) => item !== id) // Elimina el ID si está desmarcado
		);
	};




	const handleChangePage = (newPage: any) => {
		const newPageNumber = parseInt(newPage) + 1;
		const f = { ...filter, page: newPageNumber };
		setPage(newPage);
		setFilter(f);
	};

	const handleChangeRowsPerPage = (event: any) => {
		const f = { ...filter, page: 1, limit: parseInt(event.target.value, 10) }
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
		setFilter(f);
	};
	const handleFirstPageButtonClick = () => {
		handleChangePage(0);
	};

	const handleLastPageButtonClick = () => {
		handleChangePage(Math.ceil(total / rowsPerPage) - 1);
	};
	useEffect(() => {
		setUserData({ ...userData, id_users: currentUser?.user?.id });
	}, []);

	useEffect(() => {
		handleSubmit();
	}, [filter]);

	const changePaginate = (pf: any) => {
		const f = { page: pf.page, limit: pf.limit };
		setFilter(f);
	};
	const handleClose = () => {
		setOpen(false);
	};
	const isAdminCouselorOrEmployer = () => {
		return isAdminRole() || isCounselorEmployerRole() || isEmployerRole();
	}

	const handleSubmit = async () => {
		setLoading(true);
		try {
			const response = await getVagaPostulaCurriculoAll(
				`page=${filter.page}&limit=${filter.limit}&id_vacancy=${id_vacante}&nome=${userData.nombre_candidato?.trim()}&estado=${filter.estado}&autoPostulacion=${filter.autoPostulacion}`
			);
			if (response && response.data) {
				setTotal(response.data.total);
				setTotalPage(response.data.totalPages);
				setData(response.data.data);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		} finally {
			setLoading(false);
		}
	};

	/**
	* Retorna el path del usuario acorde al rol para ver resumen de la hoja de vida del candidato postulado
	* @returns 
	*/
	const getPathVerCandidato = (x: any, id: any) => {
		const userIdOfCurriculum = data.find((i: any) => i.id === id)?.UserCurriculo?.User?.id || 0;
		if (isAdminRole()) {
			return `/admin/hoja-vida/${userIdOfCurriculum}/${x.EmpresaVaga.id_empresa}/${x.id_vagas}`;
		} else if (isCounselorEmployerRole()) {
			return `/consejero/hoja-vida/${userIdOfCurriculum}/${x.EmpresaVaga.id_empresa}/${x.id_vagas}`;
		} else if (isEmployerRole()) {
			return `/empleador/hoja-vida/${userIdOfCurriculum}/${x.EmpresaVaga.id_empresa}/${x.id_vagas}`;
		} else {
			return 'auth/*';
		}
	}
	const getPathVerBitacora = (id: any) => {
		if (isAdminRole()) {
			return `/admin/gestion-bitacoras/${id}/`;
		} else if (isCounselorEmployerRole()) {
			return `/Consejero/gestion-bitacoras/${id}/`;
		} else if (isEmployerRole()) {
			return `/empleador/gestion-bitacoras/${id}/`;
		} else {
			return 'auth/*';
		}
	}
	const MenuAction = (props: any) => {

		const { data } = props;
		const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
		const open = Boolean(anchorEl);
		const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
			setAnchorEl(e.currentTarget)
		};
		const handleClose = () => {
			setAnchorEl(null);
		};

		const [openModalGestionPostulacion, setOpenModalGestionPostulacion] = useState(false);
		const [statusGestionPostulacion, setStatusGestionPostulacion] = useState('REMITIDA');

		/**
		 * Llamado servicio API para actualizar la postulación
		 * @param statusApproval 
		 */
		const updatePostulacion = async (statusApproval: any) => {
			try {
				setRequestInProgress(true);
				await updatePostulationStatus({ id: data.x.id, status: statusApproval });
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				setAlertMsg(`Postulación ${listPostulacionModel.find(i => i.code == statusGestionPostulacion)?.value || 'gestionada'} con éxito!`)
			} catch (error: any) {
				if (error.response) {
					console.error(error.response.data.error);
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error);
				} else {
					console.error('Ocurrio un error procesando la solicitud.');
				}
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 10000);
				setRequestInProgress(false);
			}
		}
		const handleSubmitCandidate = async () => {
			try {
				setRequestInProgress(true);
				await submitCandidate(data.x.id_vagas, data.x.UserCurriculo.User.id);
				setOpen(true);
				setAlert(true);
				setFilter({ ...filter });
				setAlertMsg(`Postulación ${listPostulacionModel.find(i => i.code == statusGestionPostulacion)?.value || 'gestionada'} con éxito!`)
			} catch (error: any) {
				if (error.response) {
					setOpen(true);
					setAlert(false);
					setAlertMsg(error.response.data.error.message);
				} else {
					console.error(error);
				}
			} finally {
				setTimeout(() => {
					setOpen(false);
				}, 10000);
				setRequestInProgress(false);
			}
		}

		/**
		 * Open modal confirmación gestion postulacion
		 * @param statusApproval 
		 */
		const openConfirmModalGestionPostulacion = (statusApproval: any) => {
			setStatusGestionPostulacion(statusApproval)
			setOpenModalGestionPostulacion(true);
		};

		/**
		 * Close modal confirmación gestion postulacion
		 */
		const handleCloseModalGestionPostulacion = () => {
			setStatusGestionPostulacion('REMITIDA')
			setOpenModalGestionPostulacion(false);
		};





		return (
			<>

				<Button aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}>
					<i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
				</Button>
				<Menu
					id='card-actions-menu'
					anchorEl={anchorEl}
					keepMounted
					open={open}
					onClose={handleClose}
					MenuListProps={{
						'aria-labelledby': 'basic-button',
					}}
				>
					<MenuItem onClick={handleClose}>
						<Link to={getPathVerCandidato(data.x, data.x.id)}>
							<RemoveRedEyeIcon />
							&nbsp; Ver candidato
						</Link>
					</MenuItem>
					{
						data.x.status !== 'REMITIDA' 
						? 
						<MenuItem onClick={handleClose}>
						<a className='pointer' onClick={() => openConfirmModalGestionPostulacion('REMITIDA')}>
							<SendIcon />
							&nbsp; Remitir
						</a>

					</MenuItem>
					: null
					}
					{(data.x.status === 'REMITIDA' && isAdminCouselorOrEmployer()) && (
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => openConfirmModalGestionPostulacion('CONTRATADA')}>
								<VerifiedIcon />
								&nbsp; Contratar
							</a>
						</MenuItem>
					)}
					{/* {(data.x.status === 'REMITIDA' && isAdminCouselorOrEmployer()) && ( */}
						<MenuItem onClick={handleClose}>
							<a className='pointer' onClick={() => openConfirmModalGestionPostulacion('DESCARTADA')}>
								<UnpublishedIcon />
								&nbsp; Descartar
							</a>
						</MenuItem>
					{/* // )} */}
					<MenuItem onClick={handleClose}>
						<Link to={getPathVerBitacora(data.x.id)}>
							<MenuBookIcon />
							&nbsp; Ver bitácoras
						</Link>
					</MenuItem>
				</Menu>
				{/* Dialog confirmar gestion postulacion */}
				<Dialog
					open={openModalGestionPostulacion}
					keepMounted
					onClose={handleCloseModalGestionPostulacion}
					aria-describedby='alert-dialog-description'
				>
					<DialogTitle id='alert-dialog-title'>{'Gestiónar la postulación'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>
							¿Está seguro de que desea gestionar como <b>{listPostulacionModel.find(i => i.code == statusGestionPostulacion)?.value || 'gestionar'}</b> esta postulación?

						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant='contained'
							sx={{ background: '#960a36', width: 90 }}
							onClick={handleCloseModalGestionPostulacion}
							autoFocus
							disabled={!loading ? false : true}
						>
							Cancelar
						</Button>
						<Button
							variant='contained'
							sx={{ background: '#0A4396', width: 90 }}
							// onClick={() => updatePostulacion(statusGestionPostulacion)}
							onClick={() => { statusGestionPostulacion == 'REMITIDA' ? handleSubmitCandidate() : updatePostulacion(statusGestionPostulacion) }}
							autoFocus
							disabled={!loading ? false : true}
						>
							Aceptar
						</Button>
					</DialogActions>
				</Dialog>
			</>
		);
	};



	// const handleDownloadPdfMasivo = async () => {
	// 	try {
	// 		const res = await downloadPdfMasivo(id_vacante, selectedItems);
	// 		if (res.size === 0) {
	// 			setAlert(true);
	// 			setAlertMsg('El archivo está vacío.');
	// 			setOpen(true);
	// 			return;
	// 		}
	// 		const url = URL.createObjectURL(res);
	// 		window.location.href = url;
	// 		setAlert(true);
	// 		setAlertMsg('Descargando .ZIP');
	// 		setOpen(true);
	// 	} catch (error: any) {
	// 		if (error.response) {
	// 			setAlert(error.error); // Define que el mensaje es de error
	// 			setAlertMsg(`Error al descargar el archivo.`);
	// 			setOpen(true); // Abre el Snackbar
	// 			setTimeout(() => {
	// 				setOpen(false);
	// 			}, 4000);
	// 		} else {
	// 			console.error('Ocurrio un error procesando la solicitud.');
	// 		}
	// 	}

	// };


	const handleDownloadPdfMasivo = async () => {
		try {

			
// 
			// if (!selectedItems.lenght) return

			const res = await downloadPdfMasivo(id_vacante, selectedItems);

			if (res.size === 0) {
				setAlert(true);
				setAlertMsg('El archivo está vacío.');
				setOpen(true);
				return;
			}

			const url = URL.createObjectURL(res);
			window.location.href = url;

			setAlert(true);
			setAlertMsg('Descargando .ZIP');
			setOpen(true);
		} catch (error: any) {
			if (error.response) {
				setAlert(error.error);
				setAlertMsg(`Error al descargar el archivo.`);
				setOpen(true);
				setTimeout(() => {
					setOpen(false);
				}, 4000);
			} else {
				console.error('Ocurrió un error procesando la solicitud.');
			}
		}
	};


	// const handleDownloadPdfMasivo = async () => {
	// 	try {
	// 	  // Obtener solo los IDs seleccionados
	// 	  const selectedIds = Object.keys(selectedItems).filter((id) => selectedItems[id]);

	// 	  const res = await downloadPdfMasivo(id_vacante, selectedIds);
	// 	  if (res.size === 0) {
	// 		setAlert(true);
	// 		setAlertMsg('El archivo está vacío.');
	// 		setOpen(true);
	// 		return;
	// 	  }
	// 	  const url = URL.createObjectURL(res);
	// 	  window.location.href = url;
	// 	  setAlert(true);
	// 	  setAlertMsg('Descargando .ZIP');
	// 	  setOpen(true);
	// 	} catch (error: any) {
	// 	  if (error.response) {
	// 		setAlert(error.error); 
	// 		setAlertMsg(`Error al descargar el archivo.`);
	// 		setOpen(true); 
	// 		setTimeout(() => {
	// 		  setOpen(false);
	// 		}, 4000);
	// 	  } else {
	// 		console.error('Ocurrio un error procesando la solicitud.');
	// 	  }
	// 	}
	//   };






	const [textoBuscar, setTextoBuscar] = useState('');

	const filtraTextoBuscar = () => {
		setPage(0);
		setFilter({ ...filter, page: 1 });
	}

	const handleChageTextoBuscar = (event: any) => {
		setTextoBuscar(event.target.value);
		setUserData({ ...userData, nombre_candidato: event.target.value });
	}

	const handleKeyDownEnterTextoBuscar = (event: any) => {
		if (event.key === 'Enter') {
			filtraTextoBuscar();
		}
	}

	return (
		<>
			<div className='p-4 shadow-4 rounded-3 mt-15' style={{ backgroundColor: '#FFFF' }}>
				<LoadingBackdrop loading={requestInProgress} />
				<div className='row mb-4'>
					<div className='col-12'>
						<div className='input-group d-flex' style={{ width: '100%' }}>
							<div style={{ padding: '10px 0px 0px 0px' }}>
								<div className='col-xs-2 col-sm-1 col-md-1'
									style={{
										display: 'contents',
										justifyContent: 'center',
										alignItems: 'center'
									}} >
									<BackButton />
								</div>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl fullWidth size="small" sx={{ minWidth: 175 }} >
									<InputLabel id='aupostulado'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>¿Autopostulado?</InputLabel>
									<Select
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										labelId='aupostulado'
										id='aupostulado'
										label='¿Autopostulado?'
										value={initValue(filter.autoPostulacion)}
										onChange={(e) => {
											setPage(0);
											setFilter({ ...filter, page: 1, autoPostulacion: e.target.value });
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										<MenuItem value='true'>Sí</MenuItem>
										<MenuItem value='false'>No</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-3' style={{ padding: '10px 0px 0px 15px' }}>
								<FormControl sx={{ minWidth: 175 }} fullWidth size="small">
									<InputLabel id='estado'
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>Postulación</InputLabel>
									<Select
										labelId='estado'
										id='estado'
										label='Postulación'
										value={initValue(filter.estado)}
										style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center', paddingTop: '2.48px', paddingBottom: '2.48px' }}
										onChange={(e) => {
											setPage(0);
											setFilter({ ...filter, page: 1, estado: e.target.value });
										}}
									>
										<MenuItem value=''>Seleccione...</MenuItem>
										{listPostulacionModel.map((item) => (
											<MenuItem key={item.code} value={item.code}>
												{item.value}
											</MenuItem>
										))}
									</Select>
								</FormControl>
							</div>
							<div className='col-xs-12 col-sm-6 col-md-4' style={{ padding: '10px 0px 0px 15px' }}>
								<div className={`input-group mb-3 ${styleScss['entrada-filtro-text']}`}>
									<input type='text'
										className='form-control'
										value={textoBuscar}
										onChange={handleChageTextoBuscar}
										placeholder='Buscar por Nombre/Correo'
										aria-label='Buscar por Nombre/Correo'
										aria-describedby='basic-addon2'
										onKeyDown={handleKeyDownEnterTextoBuscar} />
									<span className={`input-group-text ${styleScss['btn-buscar']}`}
										onClick={filtraTextoBuscar}
										id='searchCandidato'>
										<i className={`bi ${styleScss['bi-search2']} bi-search cursor-pointer`}
											style={{ fontSize: '20px' }}></i>
									</span>
								</div>
							</div>
							
							<div className='col-lg-1 col-sm-1 col-md-4' style={{ marginLeft: '15px' }}>
								<Button className='btn btn-primary btn-lg'
									onClick={() => {
										handleDownloadPdfMasivo()
									}}
									disabled={selectedItems.lenght === 0}
									style={{
										fontFamily: 'Poppins',
										padding: '9px 20px',
										backgroundColor: '#2266CC',
										fontSize: '15px',
										color: 'white',
										textTransform: 'initial',
										borderRadius: '10px'
									}} >
									Descarga masiva
								</Button>

							</div>
						</div>
					</div>
				</div>
				{loading && <LinearLoadingSpinner isLoading={loading} />}
				{data.lenght !== 0 && (
					<TableContainer component={Paper}>
						<Table className={`table table-responsive table-striped`}>

							<TableHead className={`${styleScss['th-custom']}`}>
								<TableRow>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', paddingLeft: '8px' }}>
										<h4>seleccion</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center', paddingLeft: '8px' }}>
										<h4>Candidato</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>N° Documento</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Correo electrónico</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Teléfono</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Código Vacante</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Postulación</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Autopostulado</h4>
									</TableCell>
									<TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
										<h4>Acciones</h4>
									</TableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{!loading && data.map((x: any, i: any) => (
									<TableRow key={i} id={i.toString()}>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', textAlign: 'center' }} className='pt-3 pb-3' scope='row'>
											<Checkbox
												key={x.id}
												checked={!!selectedItems?.includes(x?.UserCurriculo?.User?.id) || false}
												onChange={(e) => handleCheck(x?.UserCurriculo?.User?.id, e.target.checked)}
											/>
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', textAlign: 'center' }} className='pt-3 pb-3' scope='row'>
											{x.UserCurriculo.User.UserComplemento.nombre_completo}
										</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.UserCurriculo.User.UserComplemento.numero_doc}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.UserCurriculo.User.email}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.UserCurriculo.User.UserComplemento.celular}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.EmpresaVaga?.codigo_vacante}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.status.charAt(0).toUpperCase() + x.status.slice(1).toLowerCase()}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', overflowWrap: 'break-word', wordBreak: 'break-all', textAlign: 'center' }}>{x.id_counselor ? 'No' : 'Si'}</TableCell>
										<TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
											<MenuAction
												data={{ x }} />
										</TableCell>
									</TableRow>
								))}
							</TableBody>

							{data.length === 0 && (
								<tfoot>
									<TableRow>
										<TableCell colSpan={5}>
											<p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
												No se encontraron registros
											</p>
										</TableCell>
									</TableRow>
								</tfoot>
							)}
						</Table>
						<div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
							<TablePagination
								rowsPerPageOptions={[10, 20, 50, 100]}
								component="div"
								style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
								count={total}
								rowsPerPage={rowsPerPage}
								page={page}
								onPageChange={(event, newPage) => handleChangePage(newPage)}
								onRowsPerPageChange={handleChangeRowsPerPage}
								labelRowsPerPage="Filas por página:"
								labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
								ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
									<div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
										<IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
											<FirstPageIcon />
										</IconButton>
										<IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
											<ChevronLeftIcon />
										</IconButton>
										<IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
											<ChevronRightIcon />
										</IconButton>
										<IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
											<LastPageIcon />
										</IconButton>
									</div>
								)}
							/>
						</div>
					</TableContainer>
				)}
				{/* <PaginationComponent
					totalPage={totalPage}
					total={total}
					customClass={''}
					paginate={changePaginate}
				/> */}
			</div>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
				open={open}
				onClose={handleClose}
			>
				<Alert
					onClose={handleClose}
					variant={'filled'}
					severity={alert ? 'success' : 'error'}
					sx={{ whiteSpace: 'pre-line' }}
				>
					{alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
					{alertMsg}
				</Alert>
			</Snackbar>

		</>
	);
};

const EmployerRequestedCandidatoPostularWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Gestión de candidatos</PageTitle>
			<EmployerRequestedCandidatoPostularPage />
		</>
	);
};

export { EmployerRequestedCandidatoPostularWrapper };
