import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import styleScss from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import UseDidMountEffect from '../../modules/auth/core/UseDidMountEffect';
import Button from '@mui/material/Button';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import DownloadIcon from '@mui/icons-material/Download';
import AddTaskIcon from '@mui/icons-material/AddTask';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import AddBoxIcon from '@mui/icons-material/AddBox';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import LastPageIcon from '@mui/icons-material/LastPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { Alert, Snackbar, AlertTitle, Typography, Checkbox } from '@mui/material';
import { useAuth } from '../../modules/auth';
import { concatArrayToStringJoinBy, initValue, truncateWords } from '../../util/Index';
import { LinearLoadingSpinner, LoadingBackdrop } from '../../components/LoadingSpinner/LoadingSpinner';
import BackButton from '../../components/BackButton/BackButton';
import { StatusAccompanimentModel } from '../../models/StatusAccompanimentModel'
import {
    getCompanies,
    downloadZipAllFilesByCompany,
    deleteEmpresaById,
    updateStatusEmpresa,
    updateEmpresaToActive,
    updateEmpresaToDesactive,
    getAccompaniment,
    deleteAcompaniment,
    putStatusAcompaniment,

    getListOfCalls,
    deleteConvocatoria,
    getParticipantesDeLaConvocatoria,
    putAprobarParticipantes
} from '../../modules/apps/user-management/users-list/core/_requests';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Tooltip,
    TablePagination,
    Stack,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    FormControl,
    CircularProgress,
    Select,
    InputLabel
} from '@mui/material';

import DatePicker from "react-multi-date-picker";
import dayjs from 'dayjs';

import { useParams } from "react-router-dom";




interface StatusCompanyProps {
    id: number;
    status?: string;
    observaciones?: string;
}

const AprobarPaticipantes = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [accionesActivas, setAccionesActivas] = useState(false)
    const { isAdminRole, isCounselorEmployerRole, isEmployerRole } = useAuth();
    const [selectedDates, setSelectedDates] = useState<any>([]);





    const [data, setData]: any[] = useState([]);
    



    const [userData, setUserData]: any[] = useState([]);



    const [participantesAprobados, setParticipantesAprobados] = useState<any>({
        aspirantes: [],
    });
    
    




    useEffect(() => {
        const inicialData = data.map((x: any) => ({
            aspirante: x.aspirante,
            aprobar: 'si', // Por defecto todos aprobados
        }));
        setParticipantesAprobados({ aspirantes: inicialData });
    }, [data]);

    const handleCheckboxChange = (aspiranteId: any, checked: boolean) => {
        setParticipantesAprobados((prevState: any) => {
            const aspirantes = prevState.aspirantes.map((a: any) =>
                a.aspirante === aspiranteId
                    ? {
                        ...a,
                        aprobar: checked ? 'si' : 'no',
                        ...(checked ? { razon_no_aprobacion: undefined } : {}), // Eliminar razón si se aprueba
                    }
                    : a
            );
            return { ...prevState, aspirantes };
        });
    };

    const handleTextChange = (aspiranteId: any, text: string) => {
        setParticipantesAprobados((prevState: any) => {
            const aspirantes = prevState.aspirantes.map((a: any) =>
                a.aspirante === aspiranteId
                    ? { ...a, razon_no_aprobacion: text }
                    : a
            );
            return { ...prevState, aspirantes };
        });
    };






    const [companyData, setCompanyData] = useState<StatusCompanyProps>({
        id: 0,
    });
    const [loading, setLoading] = useState(false);
    const [isSubmitting, setSubmitting] = useState(false);

    const [open, setOpen] = useState(false);
    const [idConvocatoria, setIdConvocatoria] = useState();
    const [alert, setAlert] = useState(true);
    const [alertMsg, setAlertMsg] = useState('');
    //Pagination
    const [total, setTotal] = useState<number>(0);
    const [filter, setFilter] = useState<any>({
        page: 1,
        limit: 10,
        estado: ''
    });
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useEffect(() => {
        setFilter({ ...filter });
    }, []);

    useEffect(() => {
        handleGetParticipantes();
    }, []);



    const handleChangePage = (newPage: any) => {
        const newPageNumber = parseInt(newPage) + 1;
        const f = { page: newPageNumber, limit: filter.limit, estado: filter.estado };
        setPage(newPage);
        setFilter(f);
    };

    const handleChangeRowsPerPage = (event: any) => {
        const f = { page: 1, limit: parseInt(event.target.value, 10), estado: filter.estado }
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        setFilter(f);
    };
    const handleFirstPageButtonClick = () => {
        handleChangePage(0);
    };

    const handleLastPageButtonClick = () => {
        handleChangePage(Math.ceil(total / rowsPerPage) - 1);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // Get de los participantes de la convocatoria
    const handleGetParticipantes = async () => {
        setLoading(true);
        const params = {
            convocatoria_id: id
        }
        try {
            const response = await getParticipantesDeLaConvocatoria(params);
            if (response && response.data) {
                setTotal(response.data.total);
                setData(response.data.data);
            }
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        } finally {
            setLoading(false);
        }
    };

    const changeStatusEmpresa = async () => {
        try {
            setOpenAprobada(false);
            setOpenRechazada(false);
            setLoading(true);
            setSubmitting(true);
            const retorno = await updateStatusEmpresa(companyData);
            setAlertMsg(retorno.data?.message);
            setOpen(true);
            setAlert(true);
            setFilter({ ...filter });
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            }
            else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        } finally {
            setLoading(false);
            setTimeout(() => {
                setSubmitting(false);
            }, 2000);
        }
    };

    // Eliminar un acompañamiento 
    const handleDeleteConvocatoria = async (id: any) => {
        try {
            await deleteConvocatoria(id);
            setOpen(true);
            setAlert(true);
            handleCloseModal()
            setAlertMsg(`Acompañamiento eliminado con éxito!`);
            setFilter({ ...filter });
            setTimeout(() => {
                setOpen(false);
            }, 2000);
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        }

    }

    // const handleAprobarParticipantes = async (convocatoriaId: any) => {
    //     setOpenModal(false);
    //     setLoading(true);
    //     try {
    //         await putAprobarParticipantes(convocatoriaId, participantesAprobados);
    //         setOpen(true);
    //         setAlert(true);
    //         handleCloseModal()
    //         setAlertMsg(`Participantes aprobados con éxito!`);

    //         setTimeout(() => {
    //             setOpen(false);
    //             navigate(-1)
    //         }, 1500);
    //         setParticipantesAprobados({})


    //     } catch (error: any) {
    //         if (error.response) {
    //             console.error(error.response.data.error);
    //         } else {
    //             console.error('Ocurrio un error procesando la solicitud.');
    //         }
    //     } finally {
    //         setLoading(false);
    //     }
    // }
    
    const handleAprobarParticipantes = async (convocatoriaId: any) => {
        setOpenModal(false);
        setLoading(true);
        try {
            await putAprobarParticipantes(convocatoriaId, participantesAprobados);
            setOpen(true);
            setAlert(true);
            handleCloseModal()
            setAlertMsg(`Participantes aprobados con éxito!`);

            setTimeout(() => {
                setOpen(false);
                navigate(-1)
            }, 1500);
            setParticipantesAprobados({})


        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
            } else {
                console.error('Ocurrio un error procesando la solicitud.');
            }
        } finally {
            setLoading(false);
        }
    }


    


    // acctualizar un acompañamiento
    const handlePutStatusAcompaniment = async (id: any, state: any) => {
        const close = { estado: "Cerrado", }
        try {
            await putStatusAcompaniment(id, close);
            setOpen(true);
            setAlert(true);
            handleCloseModal()
            setAlertMsg(`Acompañamiento cerrado con éxito!`);
            setFilter({ ...filter });
            setTimeout(() => {
                setOpen(false);
            }, 2000);
            setOpenModalCloseAcompaniment(false)
        } catch (error: any) {
            if (error.response) {
                console.error(error.response.data.error);
                setOpen(true);
                setAlert(false);
                setAlertMsg(error.response.data.error);
                setTimeout(() => {
                    setOpen(false);
                }, 2000);
            } else {
                console.error("Ocurrio un error procesando la solicitud.");
            }
        }
    }

    const MenuAction = (props: any) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const { data } = props;

        const handleClick = (e: any) => {
            setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };


        // Eliminar Convocarotia
        // const hableEliminarConvocatoria = (id: any) => {
        // 	return `consejero/registro-de-convocatorias-asistida/${id}`;
        // }

        const hanbleEditarConvocatoria = (id: any) => {
            return `/consejero/registro-de-convocatorias-asistida/${id}`;
        };
        const hanbleVerConvocatoria = (id: any, visible: any) => {
            return `/consejero/registro-de-convocatorias-asistida/${id}/${visible}`;
        };




        return (
            <>
                <Button onClick={handleClick}>
                    <i className='bi bi-three-dots' style={{ fontSize: '20px' }}></i>
                </Button>

                <Menu
                    id='card-actions-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        [


                            <MenuItem onClick={handleClose} key="view">
                                <Button
                                    className={`${styleScss['button-link']} pointer`}
                                    onClick={() => handleOpenModal(data.id)}
                                    style={{
                                        color: '#2266cc',
                                        transition: 'all 0.3s ease',
                                        paddingLeft: 0

                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.color = 'red';
                                        e.currentTarget.style.backgroundColor = 'lightgray';
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.color = '#2266cc';
                                        e.currentTarget.style.backgroundColor = 'transparent';
                                    }}
                                >
                                    <DeleteIcon />
                                    &nbsp; Eliminar registrado
                                </Button>
                            </MenuItem>,



                        ]
                    }

                </Menu >
            </>
        )
    }




    const [openModal, setOpenModal] = useState(false);
    const [openModalCloseAcompaniment, setOpenModalCloseAcompaniment] = useState(false);

    const handleOpenModal = (id: any) => {
        setIdConvocatoria(id);
        setOpenModal(true);
    };
    const handleOpenModalClose = (id: any) => {
        setIdConvocatoria(id);
        setOpenModalCloseAcompaniment(true);
    };

    const handleCloseModalAcompaniment = () => {
        setOpenModalCloseAcompaniment(false);
    };
    const handleCloseModal = () => {
        setOpenModal(false);
    };
    const [openAprobada, setOpenAprobada] = useState(false);
    const handleOpenAprobada = (id: any) => {
        setCompanyData({ id: id, status: 'Aprobada' });
        setOpenAprobada(true);
    };
    const handleCloseAprobada = () => {
        setOpenAprobada(false);
    };
    const [openRechazada, setOpenRechazada] = useState(false);
    const handleOpenRechazada = (id: any) => {
        setUserData({ ...userData, observaciones: '' });
        setCompanyData({ id: id, status: 'Rechazada' });
        setOpenRechazada(true);
    };
    const handleCloseRechazada = () => {
        setOpenRechazada(false);
        setUserData({ ...userData, observaciones: '' });
    };

    /**
     * Valida si el rol del usuario en sesion es Administrador o Consejero
     * @returns 
     */
    const isAdminOrCounselor = () => {
        return isAdminRole() || isCounselorEmployerRole();
    }

    /**
     * Retorna el path del usuario acorde al rol
     * @returns 
     */


    const getPathRegistroConvocatoria = () => {
        return `/consejero/registro-de-convocatorias-asistida`;
    }

    /**
     * Retorna el path del usuario acorde al rol
     * @returns 
     */
    const getPathEditarEmpresa = (idAcompaniment: any) => {

        if (isCounselorEmployerRole()) {
            return `/consejero/registro-acompanamiento-empresarial/${idAcompaniment}`;
        } else {
            return 'auth/*';
        }
    }


    const [textoBuscar, setTextoBuscar] = useState('');

    const filtraTextoBuscar = () => {
        handleChangePage(0);
    }

    const handleChageTextoBuscar = (event: any) => {
        setTextoBuscar(event.target.value);
        setUserData({ ...userData, search: event.target.value });
    }


    const handleKeyDownEnterTextoBuscar = (event: any) => {
        if (event.key === 'Enter') {
            filtraTextoBuscar();
        }
    }

    const handleDateChange = (dates: any) => {
        setSelectedDates(dates);
        const searchDate = (startDate?: string, endDate?: string) => {
            setUserData({ ...userData, fecha_inicio: startDate, fecha_fin: endDate });
            setTimeout(() => {
                filtraTextoBuscar()
            }, 150);
        }
        if (dates?.length === 2) {
            const startDate = dates[0];
            const endDate = dates[1];
            searchDate(startDate.format("YYYY-MM-DD"), endDate.format("YYYY-MM-DD"))
        } else if (dates === null) {
            searchDate(undefined, undefined)
        }

    };

    const  handleConfirmarAprobacion = () => {
        setOpenModal(true);
    }



    return (
        <>
            <div className='p-4 shadow-4 rounded-3 mt-15'
                style={{ backgroundColor: '#FFFF' }}
            >
                <LoadingBackdrop loading={isSubmitting} />
                <div className='row mb-4' style={{ alignItems: 'center' }}>
                    <div className='col-sm-12'>
                        <div className='input-group d-flex' style={{ width: '100%', alignItems: 'baseline' }}>
                            {/* BackButton */}
                            <div style={{ padding: '10px 0px 0px 15px' }}>
                                <div className='col-xs-2 col-sm-1 col-md-1'
                                    style={{
                                        display: 'contents',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }} >
                                    <BackButton />
                                </div>
                            </div>
                            <div>

                                <Button 
                                    style={{ marginLeft: '20px' }} 
                                    variant="contained" 
                                    size="large" 
                                    endIcon={<LibraryAddCheckIcon />}
                                    onClick={() => {
                                        handleConfirmarAprobacion()
                                    }}
                                >
                                        Guardar evaluación
                                </Button>
                            </div>



                        </div>
                    </div>
                </div>
                {loading && <LinearLoadingSpinner isLoading={loading} />}

                <div className={`${styleScss['table-limit']}`} style={{ maxWidth: '100%', overflowWrap: 'break-word', overflowY: 'hidden' }}>
                    <Stack padding={2}>

                        {/* <TableContainer component={Paper} className={`${styleScss['table-limit']}`} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}> */}


                        <TableContainer component={Paper} style={{ overflowY: 'visible', overflowWrap: 'break-word' }}>

                            <Table style={{ overflowY: 'visible', tableLayout: 'fixed', }}>

                                <TableHead className={`${styleScss['th-custom']}`} >
                                    <TableRow>
                                        <TableCell style={{
                                            fontFamily: 'Poppins',
                                            fontSize: '16.25px',
                                            textAlign: 'center',
                                            width: '100px',
                                            maxWidth: '100px',
                                        }}>
                                            <h4>Tipo de documento </h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Número de documento`</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Primen nombre (BTA Trabaja)</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Segundo nombre (BTA Trabaja)</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Primen apeliido (BTA Trabaja)</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Segundo apellido (BTA Trabaja)</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Aprobado</h4>
                                        </TableCell>
                                        <TableCell style={{ fontFamily: 'Poppins', fontSize: '16.25px', textAlign: 'center' }}>
                                            <h4>Razon de no aprobación</h4>
                                        </TableCell>



                                    </TableRow>
                                </TableHead>

                                {!loading && <TableBody>
                                    {data.map((x: any, i: any) => {
                                        const aspiranteId = x.aspirante;
                                        const participante = participantesAprobados.aspirantes.find(
                                            (a: any) => a.aspirante === aspiranteId
                                        );
                                        const aprobado = participante ? participante.aprobar === 'si' : true;

                                        return (
                                            <TableRow key={i} id={i.toString()}>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.tipo_doc || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.numero_doc || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.primer_nome || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.segundo_nome || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.primer_apelido || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ fontFamily: 'Poppins', fontSize: '14.5px', textAlign: 'center' }}>
                                                    {x?.aspiranteUser?.UserComplemento?.segundo_apelido || 'N/A'}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <Checkbox
                                                        checked={aprobado}
                                                        onChange={(e) => handleCheckboxChange(aspiranteId, e.target.checked)}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    <TextField
                                                        id={`razon-${i}`}
                                                        disabled={aprobado}
                                                        multiline
                                                        maxRows={3}
                                                        value={participante?.razon_no_aprobacion || ''}
                                                        onChange={(e) => handleTextChange(aspiranteId, e.target.value)}
                                                        error={!participante?.razon_no_aprobacion?.trim()}
                                                        helperText={
                                                            !aprobado && !participante?.razon_no_aprobacion?.trim()
                                                                ? "No puede ser vacío."
                                                                : ""
                                                        }
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>}

                                {data.length === 0 && (
                                    <tfoot>
                                        <TableRow>
                                            <TableCell colSpan={8}>
                                                <p className='text-center' style={{ width: '100%', textAlign: 'center' }}>
                                                    No se encontraron registros
                                                </p>
                                            </TableCell>
                                        </TableRow>
                                    </tfoot>
                                )}

                            </Table>
                            <div style={{ fontFamily: 'Poppins', fontSize: '14.5px' }}>
                                <TablePagination
                                    rowsPerPageOptions={[10, 20, 50, 100]}
                                    component="div"
                                    style={{ overflow: 'visible', fontFamily: 'Poppins', fontSize: '14.5px' }}
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(event, newPage) => handleChangePage(newPage)}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Filas por página:"
                                    labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                    ActionsComponent={({ onPageChange, page: number, count, rowsPerPage }) => (
                                        <div style={{ display: 'flex', alignItems: 'center', fontFamily: 'Poppins', fontSize: '14.5px' }}>
                                            <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
                                                <FirstPageIcon />
                                            </IconButton>
                                            <IconButton onClick={(event) => onPageChange(event, page - 1)} disabled={page === 0} aria-label="previous page">
                                                <ChevronLeftIcon />
                                            </IconButton>
                                            <IconButton onClick={(event) => onPageChange(event, page + 1)} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
                                                <ChevronRightIcon />
                                            </IconButton>
                                            <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
                                                <LastPageIcon />
                                            </IconButton>
                                        </div>
                                    )}
                                />
                            </div>
                        </TableContainer>
                    </Stack>
                </div>
            </div>
            {/* modal de eliminar */}
            <div>
                <Dialog
                    open={openModal}
                    keepMounted
                    onClose={handleCloseModal}
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>{'Confirma que deseas aprobar participantes'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Una vez realizado no es posible deshacer la acción.
                        </DialogContentText>
                        <br />
                        <DialogContentText id='alert-dialog-description'>
                            ¿Desea continuar con la aprobación de los participantes?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90, }}
                            onClick={handleCloseModal}
                            autoFocus
                            disabled={!loading ? false : true}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', width: 90 }}
                            onClick={() => handleAprobarParticipantes(id)}
                            autoFocus
                            disabled={!loading ? false : true}
                        >
                            Aprobar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
            {/* modal Cerrar */}
            <div>
                <Dialog
                    open={openModalCloseAcompaniment}
                    keepMounted
                    onClose={handleCloseModalAcompaniment}
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>{'Confirme que desea cerrar este acompañamiento.'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            ¿Está seguro de que desea cerrar este acompañamiento?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90 }}
                            onClick={handleCloseModalAcompaniment}
                            autoFocus
                            disabled={!loading ? false : true}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', width: 90 }}
                            onClick={() => handlePutStatusAcompaniment(idConvocatoria, 'cerrar')}
                            autoFocus
                            disabled={!loading ? false : true}
                        >
                            Cerrar
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openAprobada}
                    keepMounted
                    onClose={handleCloseAprobada}
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        {'Confirmación de aprobación de empresa'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            ¿Está seguro de que desea aprobar esta empresa?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90 }}
                            onClick={handleCloseAprobada}
                            disabled={isSubmitting}
                            autoFocus
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', width: 90 }}
                            onClick={() => changeStatusEmpresa()}
                            disabled={isSubmitting}
                            autoFocus
                        >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
                                {'Aprobar'}
                            </span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog
                    open={openRechazada}
                    keepMounted
                    onClose={handleCloseRechazada}
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>{'Rechazar la empresa'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            ¿Está seguro de que desea rechazar esta empresa?
                        </DialogContentText>
                        <DialogContentText id='alert-dialog-description' style={{ marginBottom: 25 }}>
                            Escriba las razones del rechazo a continuación
                        </DialogContentText>
                        <FormControl sx={{ minWidth: '100%' }}>
                            <TextField
                                fullWidth
                                sx={{ width: 500, height: 250 }}
                                rows={10}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                value={initValue(companyData['observaciones'])}
                                onChange={(e) => setCompanyData({ ...companyData, observaciones: e.target.value })}
                                size='medium'
                                multiline
                                inputProps={{ maxLength: 300 }}
                                disabled={loading}
                                helperText={`${companyData['observaciones'] === undefined ? '0' : companyData['observaciones']?.length}/${1200}`}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='contained'
                            sx={{ background: '#960a36', width: 90 }}
                            onClick={handleCloseRechazada}
                            autoFocus
                            disabled={isSubmitting}
                        >
                            Cancelar
                        </Button>
                        <Button
                            variant='contained'
                            sx={{ background: '#0A4396', width: 90 }}
                            onClick={() => changeStatusEmpresa()}
                            autoFocus
                            disabled={companyData['observaciones'] === undefined || companyData['observaciones'] === '' || companyData['observaciones']?.trim() === ''}
                        >
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                                {isSubmitting && <CircularProgress size={20} style={{ marginRight: 2 }} />}
                                {'Rechazar'}
                            </span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    variant={'filled'}
                    severity={alert ? 'success' : 'error'}
                    sx={{ whiteSpace: 'pre-line' }}
                >
                    {alert ? <AlertTitle>Éxito</AlertTitle> : <AlertTitle>Error</AlertTitle>}
                    {alertMsg}
                </Alert>
            </Snackbar>
        </>
    );
};

const ApproveParticipants = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Tabla de asistentes</PageTitle>
            <AprobarPaticipantes />
        </>
    );
};

export { ApproveParticipants };
