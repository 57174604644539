import { useEffect, useState } from 'react';
import { PageTitle } from '../../../_metronic/layout/core';
import { getMeetsByCouselor, getUserDataById } from '../../modules/apps/user-management/users-list/core/_requests';
import { useAuth } from '../../modules/auth';
import styleScss from './styles.module.scss';
import Chatbot from '../../components/Chatbot';
import { CardModel } from '../../models/CardModel';
import { Link } from 'react-router-dom';
import { convertUTCDateToLocalDate, truncateWords } from '../../util/Index';
import moment from 'moment';

const CounselorAreaPage = () => {
	const { currentUser, isCounselorCandidateRole, isCounselorEmployerRole } = useAuth();
	const [meets, setMeets]: any[] = useState([]);
	const [agendaHabilitada, setAgendaHabilitada]: any[] = useState(false);

	let listManage: CardModel[];

	if (isCounselorCandidateRole()) {
		listManage = [
			{
				title: 'Gestionar candidatos',
				content: 'Crear, editar, activar e inactivar candidatos',
				action: '/consejero/gestion-candidatos',
				actionName: 'Ver más',
				icon: 'bi-people'
			},
			{
				title: 'Formación asistida (Presencial)',
				content: 'Registar, Cargue, Evaluación y Certificado',
				action: '/consejero/formacion-asistida',
				actionName: 'Ver más',
				icon: 'bi-person-workspace'
			}
		];
	} else if (isCounselorEmployerRole()) {
		listManage = [
			{
				title: 'Gestionar empresas',
				content: 'Crear, editar, eliminar, activar y desactivar empresas.',
				action: '/consejero/gestion-empresas',
				actionName: 'Ver más',
				icon: 'bi-newspaper'
			},
			{
				title: 'Gestionar vacantes',
				content: 'Crear, editar, activar e inactivar vacantes.',
				action: '/consejero/gestion-vacantes',
				actionName: 'Ver más',
				icon: 'bi-layout-text-sidebar'
			},
			{
				title: 'Gestionar candidatos',
				content: 'Crear, editar, activar e inactivar candidatos',
				action: '/consejero/gestion-candidatos',
				actionName: 'Ver más',
				icon: 'bi-people'
			},
			{
				title: 'Acompañamiento empresarial',
				content: 'Registrar observaciones',
				action: '/consejero/acompanamiento-empresarial',
				actionName: 'Ver más',
				icon: 'bi-building'
			}
		];
	} else {
		listManage = [
			{
				title: 'No tienes permisos para esta función',
				content: 'Por favor, contacta al administrador para obtener acceso.',
				action: '',
				actionName: '',
				icon: 'bi-error'
			}
		];
	}

	useEffect(() => {
		getUserInfo();
		loadMeets();
	}, []);

	/**
	 * Obtiene la informacion de los meets
	 */
	async function loadMeets() {
		try {
			if (isCounselorCandidateRole()) {
				await getMeetsByCouselor(`id_organizador=${currentUser?.user?.id}&activos=true&vigentes=true`).then((response) => {
					setMeets(response.data);
				});
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}

	/**
	 * Obtiene la informacion del usuario
	 */
	async function getUserInfo() {
		try {
			if (isCounselorCandidateRole()) {
				const response = await getUserDataById(`id=${currentUser?.user?.id}`);
				setAgendaHabilitada((response?.data?.habilitarAgenda == true || response?.data?.habilitarAgenda == 1 || response?.data?.habilitarAgenda == '1') || false);
			}
		} catch (error: any) {
			if (error.response) {
				console.error(error.response.data.error);
			} else {
				console.error('Ocurrio un error procesando la solicitud.');
			}
		}
	}


	/**
	 * Contenido card Citas programadas
	 * @returns 
	 */
	const overwriteContentImpCitasProgramadas = () => {
		return (
			<>
				<div className='row' style={{ overflowX: 'auto', overflowY: 'hidden' }}>
					{meets.slice(0, 10).map((x: any, i: any) => (
						<div
							onClick={() => window.open(x.link_event, '_blank')}
							className='mb-2'
							style={{ display: 'flex', gap: '10px', cursor: 'pointer' }}
							key={x.id}
							id={x.id}
						>
							<div className={`${styleScss['box']}`}>
								<p className={`${styleScss['txt-center']}`}>
									{new Date(x.inicio).toJSON().slice(5, 10).split('-').reverse().join('/')}
								</p>
							</div>
							<div>
								<p className='text-primary'>
									<b>{truncateWords(x.descripcion, 20)}</b>
									<br />
									<span className='text-black'>
										{x.inicio ? moment(new Date(x.inicio).toUTCString()).format('hh:mm a') : ''}
									</span>
								</p>
							</div>
						</div>
					))}
				</div>
			</>
		)
	}
	let listManage2: CardModel[] = [];

	//Habilita card de agendamiento
	if (isCounselorCandidateRole() && agendaHabilitada == true) {
		listManage2 = [
			{
				title: 'Citas programadas',
				content: 'Calendario de tutoría y entrevistas.',
				action: `/consejero/citas-programadas`,
				actionName: 'Ver más',
				icon: 'bi-calendar-check',
				overwriteContent: true, //Determina si se debe sobrescribir el contenido por defecto 
				overwriteContentImp: overwriteContentImpCitasProgramadas //Contenido a sobre escribir
			}
		];
	}


	return (
		<>
			{/* <h2>Área del consejero</h2> */}
			<div className='row mt-15'>
				{/* Cards */}
				<div className={`col-sm-4 mt-5`}>
					{listManage.map((x: CardModel, i: number) => (
						<div
							className={`card mb-10 ${styleScss['card']}`}
							style={{ borderRadius: '10px' }}
							key={x.title}
							id={x.title}
						>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-5'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
				{/* Calendar */}
				<div className='col-sm-4 mt-5'>
					{listManage2.length > 0 && listManage2.map((x: CardModel, i: number) => (
						<div className={`card mb-10 ${styleScss['card']}`}
							key={x.title}
							id={x.title}>
							<div className={`${styleScss['top-icon']}`}>
								<i className={`bi ${x.icon} ${styleScss['icon-top']}`}></i>
							</div>
							<div className='card-body mt-3'>
								<h2 className='card-title'>{x.title}</h2>
								<p className='card-text'>{x.content}</p>
								{x.overwriteContent && (<><x.overwriteContentImp /><br /></>)}
								{x.action && (
									<Link to={x.action!}
										className='btn btn-primary btn-lg btn-block'
										style={{ padding: '8px 40px' }}>
										{x.actionName}
									</Link>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const CounselorAreaWrapper = () => {
	return (
		<>
			<PageTitle breadcrumbs={[]}>Área Consejero</PageTitle>
			<CounselorAreaPage />
			<Chatbot />
		</>
	);
};

export { CounselorAreaWrapper };
function fetchData() {
	throw new Error('Function not implemented.');
}
